import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, importProvidersFrom, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { ActionReducer, MetaReducer, provideStore } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { MARKED_OPTIONS, provideMarkdown } from 'ngx-markdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FirebaseConfigModule } from './core/firebase/firebase.module';
import { InterceptorService } from './core/services/interceptor.service';
import { AuthEffects, authReducerFactory } from './core/store/auth';
// import ngx-translate and the http loader
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { AppLayoutModule } from './core/layout/components/layout/app.layout.module';
import { botStateReducerFactory } from './core/store/bot';
import { BotEffects } from './core/store/bot/bot.effects';
import { LanguageEffects } from './core/store/language/language.effects';
import { languageReducer } from './core/store/language/language.reducer';
import { routerStateReducerFactory } from './core/store/router/router.reducer';
import { DynamicLocaleService } from './main/services/dynamic-locale.service';
import { GlobalErrorHandlerService } from './main/services/global-error-handler.service';
import { MainLanguageService } from './main/services/main-language.service';
import { initializeLocale, localeIdFactory } from './main/shared/functions/load-language.functions';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMarkdownEditorModule } from 'angular-markdown-editor';
import { MessageService } from 'primeng/api';
import { DriveEffects } from './core/store/drive/drive.effects';
import { driveStateReducerFactory } from './core/store/drive/drive.reducer';
import { SharedModule } from './main/shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { auth: ['user', 'token'] },
      { language: ['language', 'error'] },
      { botStore: ['bot', 'lastChats'] },
      { routerState: ['url'] },
      { drive: ['orderBy'] },
    ],
    rehydrate: true,
    removeOnUndefined: false,
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

/**
 * E’ un modulo base che contiene i layoutModule dell’applicazione e tutti i moduli di Angular.
 *
 * Routing definition in {@link AppRoutingModule}
 *
 */

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppLayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FirebaseConfigModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularMarkdownEditorModule.forRoot({
      height: 200,
      iconlibrary: 'fa',
      initialstate: 'editor',
      hiddenButtons: [],
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLocale,
      deps: [MainLanguageService, DynamicLocaleService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: localeIdFactory,
      deps: [DynamicLocaleService],
    },
    provideStore(
      {
        auth: authReducerFactory,
        language: languageReducer,
        botStore: botStateReducerFactory,
        routerState: routerStateReducerFactory,
        drive: driveStateReducerFactory,
      },
      { metaReducers }
    ),
    provideRouterStore(),
    provideEffects([AuthEffects, LanguageEffects, BotEffects, DriveEffects]),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
    environment.providers,
    provideMarkdown({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: true,
          breaks: false,
          pedantic: false,
          smartLists: true,
          smartypants: false,
        },
      },
    }),
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
