import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of } from 'rxjs';
import { clearDriveAction, setOrderByAction, setOrderByFailureAction, setOrderBySuccessAction } from './drive.actions';
import { logoutAction } from '../auth/auth.actions';

@Injectable()
export class DriveEffects {
  constructor(private readonly actions$: Actions) {}

  readonly setOrderBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setOrderByAction),
      map(({ orderBy }) => setOrderBySuccessAction({ orderBy })),
      catchError((error) => of(setOrderByFailureAction({ error })))
    )
  );

  readonly logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutAction),
      map(() => clearDriveAction())
    )
  );
}
