import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/**
 *
 * Contiene la rotta iniziale del progetto che porta al mainModule dell’applicazione.
 *
 */
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
