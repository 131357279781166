import { routerNavigatedAction } from '@ngrx/router-store';
import { Action, createReducer, on } from '@ngrx/store';

export interface IRouterStoreState {
  url?: string;
}

export const storeState: IRouterStoreState = {
  url: undefined,
};

export const storeReducer = createReducer(
  storeState,
  on(routerNavigatedAction, (state, { payload }) => ({
    ...state,
    url: payload.routerState.url,
  }))
);

export const routerStateReducerFactory = (state: IRouterStoreState | undefined, action: Action) => storeReducer(state, action);
