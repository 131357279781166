import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService {
  constructor() {}

  handleError(error: any): void {
    const chunkFailedMessage = /[\w]+: Loading chunk [\w -]+ failed/;
    if (chunkFailedMessage.test(error.message) || error.message.includes('Failed to fetch dynamically imported module')) {
      //@todo: migliorare e integrare in PWA
      if(confirm('A new version is Available! Press Ok to update')){
        window.location.reload();
      }
    } else {
      //@todo: differenziare tra PROD e STAGING e DEV
      console.error(error);
    }
  }
}
