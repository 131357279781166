import { createReducer, on } from '@ngrx/store';
import { setLanguageFailureAction, setLanguageSuccessAction } from './language.actions';
import { LanguagesEnum } from 'src/app/main/shared/enums/app-languages.enum';

export interface IInitialState {
  language: string | undefined;
  error: {
    language: any;
  };
}

export const initialValue: IInitialState = {
  language: LanguagesEnum.ENG_US,
  error: {
    language: undefined,
  },
};

export const languageReducer = createReducer(
  initialValue,
  on(setLanguageSuccessAction, (state, { language }) => ({
    ...state,
    language,
    error: {
      ...state.error,
      language: undefined,
    },
  })),
  on(setLanguageFailureAction, (state, { error }) => ({
    ...state,
    language: LanguagesEnum.ENG_US,
    error: {
      ...state.error,
      language: error,
    },
  }))
);
