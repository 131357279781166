import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { Observable, catchError, first, mergeMap, switchMap, throwError } from 'rxjs';
import { Token } from '../models/token.model';
import { AuthState, logoutAction, refreshTokenFailureAction, refreshTokenSuccessAction, selectToken } from '../store/auth';
import { AuthService } from './auth.service';
import { ofType } from '@ngrx/effects';
import { AUTH } from '../enums/auth.enum';
import _ from 'lodash';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly store: Store<{ auth: AuthState }>,
    private readonly actions: ActionsSubject,
    private readonly authService: AuthService
  ) {}
  private excludedEndpoints: string[] = ['/login', '/forgot-password', '/register', '/preview/', 'exclude', 'share'];

  // @Cache({})
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      (this.excludedEndpoints.some((endpoint) => request.url.includes(endpoint)) && !request.headers.get('Allow-Interceptor')) ||
      request.headers.get('Allow-Interceptor')
    ) {
      return next.handle(request);
    }
    return this.store.select(selectToken).pipe(
      first(),
      mergeMap((token: Token | undefined) => {
        if (token) {
          request = request.clone({
            // headers: request.headers.append('Authorization', `Bearer ${token.accessToken}faf`),
            // url: request.url,
            setHeaders: {
              Authorization: `Bearer ${token.accessToken}`,
            },
          });
        }

        return next.handle(request).pipe(catchError((response) => this.refreshNewToken(next, request, response, token)));
      })
    );
  }

  private refreshNewToken(next: HttpHandler, request: HttpRequest<any>, response: HttpResponse<any>, currentToken: Token | undefined) {
    if (response.status === 401 || response.status === 403) {
      if (currentToken && currentToken.refreshToken) {
        this.authService.refreshToken(currentToken);
        return this.actions.pipe(
          ofType(refreshTokenSuccessAction, refreshTokenFailureAction),
          mergeMap((payload) => {
            if (payload.type === AUTH.refreshTokenFailure) {
              return this.goToLogin(response);
            } else {
              const clonedRequestRepeat = request.clone({
                url: request.url,
                setHeaders: {
                  Authorization: `Bearer ${payload.token.accessToken}`,
                },
              });
              return next.handle(clonedRequestRepeat);
            }
          })
        );
      } else {
        return this.goToLogin(response);
      }
    } else {
      return throwError(() => response);
      // return this.goToLogin(response); // @todo non buttarlo fuori, restituisce la risposta che arriva
    }
  }

  private goToLogin(response: HttpResponse<any>) {
    this.store.dispatch(logoutAction());
    this.router.navigate(['public']);
    return throwError(() => response);
  }
}
