import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { DateTime, Settings } from 'luxon';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LanguagesEnum } from 'src/app/main/shared/enums/app-languages.enum';
import { setLanguageAction, setLanguageFailureAction, setLanguageSuccessAction } from './language.actions';

@Injectable()
export class LanguageEffects {
  constructor(private readonly translateService: TranslateService, private readonly actions$: Actions) {}

  readonly setLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setLanguageAction),
      tap(({ language }) => {
        this.translateService.setDefaultLang(language === LanguagesEnum.ENG ? LanguagesEnum.ENG_US : language);
        Settings.defaultLocale = language.split('-')[0];
        DateTime.local().setLocale(language.split('-')[0]);
      }),
      map(({ language }) => setLanguageSuccessAction({ language })),
      catchError((error) => of(setLanguageFailureAction({ error })))
    )
  );
}
