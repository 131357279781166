import { AfterViewInit, Component } from '@angular/core';
import { AnalyticsService } from './main/services/analytics.service';
import { FaviconService } from './main/services/favicon.service';
import { MainLanguageService } from './main/services/main-language.service';
import { WebComponentService } from './main/services/web-component.service';
import { LANGUAGES } from './main/shared/constants/languages.items';
import { LanguagesEnum } from './main/shared/enums/app-languages.enum';
import { AiModelsService } from './main/services/ai-models.service';
import { SsoService } from './main/services/sso.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  constructor(
    private readonly ssoService: SsoService,
    private readonly faviconService: FaviconService,
    private readonly analyticsService: AnalyticsService,
    private readonly languageService: MainLanguageService,
    private readonly webComponentService: WebComponentService
  ) {
    this.languageService.defaultLanguage$.subscribe({
      next: (v) => {
        if (v === LanguagesEnum.ENG) {
          languageService.useLanguage(LanguagesEnum.ENG_US);
        }
      },
    });

    this.languageService.addLanguages(LANGUAGES.map((l) => l.value));

    this.faviconService.setFavicon();

    this.webComponentService.changeWebComponentUrl();
  }

  ngAfterViewInit(): void {
    this.analyticsService.appendScripts();
    this.ssoService.appendAppleScript();
    this.ssoService.appendGoogleScript();
  }
}
