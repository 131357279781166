import { Action, createReducer, on } from '@ngrx/store';
import { clearDriveAction, setOrderByFailureAction, setOrderBySuccessAction } from './drive.actions';

export interface IDriveStoreState {
  orderBy?: string;
  error?: any;
}

export const driveStoreState: IDriveStoreState = {
  orderBy: undefined,
  error: undefined,
};

export const driveStoreReducer = createReducer(
  driveStoreState,
  on(setOrderBySuccessAction, (state, { orderBy }) => ({
    ...state,
    orderBy,
    error: undefined,
  })),
  on(setOrderByFailureAction, (state, { error }) => ({
    ...state,
    orderBy: undefined,
    error,
  })),
  on(clearDriveAction, (state) => driveStoreState)
);

export function driveStateReducerFactory(state: IDriveStoreState | undefined, action: Action) {
  return driveStoreReducer(state, action);
}
