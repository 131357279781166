import { take } from 'rxjs';
import { MainLanguageService } from '../../services/main-language.service';
import { DynamicLocaleService } from '../../services/dynamic-locale.service';
import { LanguagesEnum } from '../enums/app-languages.enum';

export function initializeLocale(mainLanguageService: MainLanguageService, dynamicLocaleService: DynamicLocaleService) {
  return () =>
    new Promise<void>((resolve) => {
      mainLanguageService.defaultLanguage$.pipe(take(1)).subscribe({
        next: (storedLanguage) => {
          if (storedLanguage && (Object.values(LanguagesEnum) as string[]).includes(storedLanguage)) {
            dynamicLocaleService.locale = storedLanguage;
          } else {
            const browserLanguage = window.navigator.language;
            const locale = (Object.values(LanguagesEnum) as string[]).includes(browserLanguage) ? browserLanguage : 'en-US';
            dynamicLocaleService.locale = locale;
          }
          resolve();
        },
        error: () => {
          dynamicLocaleService.locale = 'en-US';
          resolve();
        },
      });
    });
}

export function localeIdFactory(dynamicLocaleService: DynamicLocaleService) {
  return dynamicLocaleService.locale;
}
