import { AfterViewInit, Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from './main/services/google-analytics.service';
import { FaviconService } from './main/services/favicon.service';
import { MainLanguageService } from './main/services/main-language.service';
import { SsoService } from './main/services/sso.service';
import { UpdateService } from './main/services/update-version.service';
import { WebComponentService } from './main/services/web-component.service';
import { LANGUAGES } from './main/shared/constants/languages.items';
import { LanguagesEnum } from './main/shared/enums/app-languages.enum';
import { DialogService } from 'primeng/dynamicdialog';
import { UpdatePwaModalComponent } from './main/shared/components/update-pwa-modal/update-pwa-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  constructor(
    private readonly ssoService: SsoService,
    private readonly updateService: UpdateService,
    private readonly dialogService: DialogService,
    private readonly faviconService: FaviconService,
    private readonly analyticsService: GoogleAnalyticsService,
    private readonly mainLanguageService: MainLanguageService,
    private readonly webComponentService: WebComponentService
  ) {
    this.pupauLog();
    this.mainLanguageService.defaultLanguage$.subscribe({
      next: (v) => {
        if (v === LanguagesEnum.ENG) {
          this.mainLanguageService.useLanguage(LanguagesEnum.ENG_US);
        }
      },
    });

    this.mainLanguageService.addLanguages(LANGUAGES.map((l) => l.value));

    this.faviconService.setFavicon();

    this.webComponentService.changeWebComponentUrl();
  }

  ngAfterViewInit(): void {
    this.analyticsService.appendScripts();
    this.ssoService.appendAppleScript();
    this.ssoService.appendGoogleScript();
    this.removeIndexLoader();

    this.updateService.getUpdates$().subscribe((updateFound) => {
      if (updateFound && !this.updateService.hasAlreadyPrompted) {
        this.showUpdatePwaModal();
      }
    });
  }

  pupauLog() {
    const pink = '\x1b[38;2;255;20;147m';
    const blue = '\x1b[38;2;65;105;225m';
    const reset = '\x1b[0m';

    console.log(`
${pink}▗▄▄▖ ▗▖ ▗▖▗▄▄▖  ▗▄▖ ▗▖ ▗▖ ${blue}    ▗▄▖ ▗▄▄▄▖
${pink}▐▌ ▐▌▐▌ ▐▌▐▌ ▐▌▐▌ ▐▌▐▌ ▐▌ ${blue}   ▐▌ ▐▌  █  
${pink}▐▛▀▘ ▐▌ ▐▌▐▛▀▘ ▐▛▀▜▌▐▌ ▐▌ ${blue}   ▐▛▀▜▌  █  
${pink}▐▌   ▝▚▄▞▘▐▌   ▐▌ ▐▌▝▚▄▞▘ ${blue}   ▐▌ ▐▌▗▄█▄▖
${reset}`);
    console.log(`            
${blue} What are you doing all alone here? Let's keep in touch! 
${blue} Write us at hello@pupau.ai ${reset}`);

    if (environment.production && window.location.hostname === 'app.pupau.ai') {
      console.log = () => {};
      console.warn = () => {};
    }
  }

  removeIndexLoader() {
    document.querySelector('.index')?.remove();
  }

  showUpdatePwaModal() {
    this.dialogService.open(UpdatePwaModalComponent, {
      header: this.mainLanguageService.instant('private.updatePwaModal.header'),
      styleClass: 'update-pwa-modal',
    });
  }
}
