import { Injectable } from '@angular/core';
import { FAVICON_SELECTOR, FAVICONS, PREFER_DARK_THEME } from '../shared/constants/theme.consts';

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  constructor() {
    window.matchMedia(PREFER_DARK_THEME).addEventListener('change', () => this.setFavicon());
  }

  setFavicon() {
    const favicon = document.querySelector<HTMLLinkElement>(FAVICON_SELECTOR);
    if (favicon) {
      // favicon.href = window.matchMedia(PREFER_DARK_THEME).matches ? FAVICONS.dark : FAVICONS.light;
      favicon.href = FAVICONS.dark;
    }
  }
}
